var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "text-center",
      staticStyle: { "max-width": "20rem" },
      attrs: {
        header: _vm.virtualPhone.company_name,
        "header-tag": "header",
        tag: "article",
      },
    },
    [
      _c("b-card-text", [
        _c("p", [_c("b", [_vm._v(_vm._s(_vm.virtualPhone.name))])]),
        _vm.virtualPhone.number
          ? _c("p", {
              directives: [
                {
                  name: "phone-number",
                  rawName: "v-phone-number",
                  value: _vm.virtualPhone.number,
                  expression: "virtualPhone.number",
                },
              ],
              staticClass: "mb-3 mt-1",
            })
          : _c("p", { staticClass: "mb-0 mt-1" }, [
              _vm._v("\n      Number Not Configured\n    "),
            ]),
        _c(
          "div",
          [
            _c(
              "b-avatar",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "m-1",
                attrs: { button: "", title: "Dial", variant: "info" },
                on: {
                  click: function ($event) {
                    return _vm.nav("call")
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "phone-alt" } })],
              1
            ),
            _c(
              "b-avatar",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "m-1",
                attrs: {
                  button: "",
                  title: "Messages",
                  variant: "info",
                  badge: _vm.smsCount,
                  "badge-variant": "danger",
                },
                on: {
                  click: function ($event) {
                    return _vm.nav("sms-chat")
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "sms" } })],
              1
            ),
            _c(
              "b-avatar",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "m-1",
                attrs: {
                  button: "",
                  title: "Voicemail",
                  variant: "info",
                  badge: _vm.voicemailCount,
                  "badge-variant": "danger",
                },
                on: {
                  click: function ($event) {
                    return _vm.nav("voicemails")
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "voicemail" } })],
              1
            ),
            _c(
              "b-avatar",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "m-1",
                attrs: { button: "", title: "Settings", variant: "info" },
                on: {
                  click: function ($event) {
                    return _vm.nav("settings")
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "cog" } })],
              1
            ),
            _c(
              "b-avatar",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "m-1",
                attrs: { button: "", title: "Contacts", variant: "info" },
                on: {
                  click: function ($event) {
                    return _vm.nav("contacts")
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "address-book" } })],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-card
    :header="virtualPhone.company_name"
    header-tag="header"
    tag="article"
    style="max-width: 20rem;"
    class="text-center"
  >
    <b-card-text>
      <p><b>{{ virtualPhone.name }}</b></p>
      <p v-if="virtualPhone.number" v-phone-number="virtualPhone.number" class="mb-3 mt-1" />
      <p v-else class="mb-0 mt-1">
        Number Not Configured
      </p>
      <div>
        <b-avatar
          v-b-tooltip.hover
          button
          title="Dial"
          variant="info"
          class="m-1"
          @click="nav('call')"
        >
          <fa-icon icon="phone-alt" />
        </b-avatar>
        <b-avatar
          v-b-tooltip.hover
          button
          title="Messages"
          variant="info"
          :badge="smsCount"
          badge-variant="danger"
          class="m-1"
          @click="nav('sms-chat')"
        >
          <fa-icon icon="sms" />
        </b-avatar>
        <b-avatar
          v-b-tooltip.hover
          button
          title="Voicemail"
          variant="info"
          :badge="voicemailCount"
          badge-variant="danger"
          class="m-1"
          @click="nav('voicemails')"
        >
          <fa-icon icon="voicemail" />
        </b-avatar>
        <b-avatar
          v-b-tooltip.hover
          button
          title="Settings"
          variant="info"
          class="m-1"
          @click="nav('settings')"
        >
          <fa-icon icon="cog" />
        </b-avatar>
        <b-avatar
          v-b-tooltip.hover
          button
          title="Contacts"
          variant="info"
          class="m-1"
          @click="nav('contacts')"
        >
          <fa-icon icon="address-book" />
        </b-avatar>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { phoneNumber } from '@/directives/directives'

export default {
  name: 'VirtualPhoneCard',
  components: {},
  directives: {
    phoneNumber,
  },
  props: {
    virtualPhone: {
      type: Object,
      default () {
        return {
          company_name: '',
          name: '',
          number: '',
        }
      },
    },
  },
  computed: {
    // The Badge prop only accepts String and Boolean types.
    voicemailCount () {
      const countOrNull = this.virtualPhone.unheard_voicemails_count
      return countOrNull ? countOrNull.toString() : false
    },
    smsCount() {
      const smsCountOrNull = this.virtualPhone.unread_text_messages_count
      return smsCountOrNull ? smsCountOrNull.toString() : false
    },
  },
  methods: {
    nav (route) {
      this.$router.push({ name: route, params: { virtualPhoneId: this.virtualPhone.id } })
    },
  },
}
</script>
<style lang="scss" scoped>
.b-avatar {
  width: 2em !important;
  height: 2em !important;
  padding: 0 !important;
}
</style>
